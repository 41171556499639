import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Tooltip } from '../popovers';

export default function SaveStatus(props) {
  const { isSaving, isSuccess, isFailure } = props;
  return (
    <>
      {isSaving ? (
        <FontAwesomeIcon icon={faSpinner} spin={true} color="blue" />
      ) : (
        <></>
      )}
      {isSuccess ? (
        <FontAwesomeIcon icon={faCheckCircle} color="green" />
      ) : (
        <></>
      )}
      {isFailure ? (
        <Tooltip
          content={<div style={{ minWidth: '200px' }}>{isFailure}</div>}
          placement="top"
        >
          <FontAwesomeIcon icon={faExclamationCircle} color="red" />
        </Tooltip>
      ) : (
        <></>
      )}
    </>
  );
}
