import React from 'react';
// import Popper from '@popppopper.js'
import { createPopper } from '@popperjs/core';
// import dynamic from "next/dynamic";

// const createPopper = dynamic(import("@popperjs/core"));

const placements = ['auto', 'top', 'right', 'bottom', 'left']; //-start, -end

export const OldPopover = ({ placement, title, content, children }) => {
  const [popoverShow, setPopoverShow] = React.useState(false);
  const btnRef = React.createRef();
  const popoverRef = React.createRef();
  const openPopover = () => {
    createPopper(btnRef.current, popoverRef.current, {
      placement: placement,
    });
    setPopoverShow(true);
  };
  const closePopover = () => {
    setPopoverShow(false);
  };
  let margin = 'm-0';
  if (placement === 'left') margin = 'mr-2';
  if (placement === 'right') margin = 'ml-2';
  if (placement === 'top') margin = 'mb-2';
  if (placement === 'bottom') margin = 'mt-2';

  return (
    <>
      <button
        // className="btn btn-default btn-rounded"
        className="w-full"
        type="button"
        onClick={() => {
          popoverShow ? closePopover() : openPopover();
        }}
        ref={btnRef}
      >
        {children}
      </button>
      <div
        className={`popover ${popoverShow ? 'popover-show ' : ''}`}
        ref={popoverRef}
      >
        <div className="popover-title">{title}</div>
        <div className="popover-content">{content}</div>
        <div id="arrow" data-popper-arrow></div>
      </div>
    </>
  );
};

export const Tooltip = ({ placement, title, content, children }) => {
  const [tooltipShow, setPopoverShow] = React.useState(false);
  const btnRef = React.createRef();
  const tooltipRef = React.createRef();
  const openPopover = () => {
    createPopper(btnRef.current, tooltipRef.current, {
      placement: placement || 'top',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [40, 20],
          },
        },
      ],
    });
    setPopoverShow(true);
  };
  const closePopover = () => {
    setPopoverShow(false);
  };
  let margin = 'm-0';
  if (placement === 'left') margin = 'mr-2';
  if (placement === 'right') margin = 'ml-2';
  if (placement === 'top') margin = 'mb-2';
  if (placement === 'bottom') margin = 'mt-2';

  const Child = children; //React.cloneElement(children);

  return (
    <>
      <div
        className="inline"
        onMouseEnter={openPopover}
        onMouseLeave={closePopover}
        onClick={() => {
          tooltipShow ? closePopover() : openPopover();
        }}
        ref={btnRef}
      >
        {children}
      </div>
      <div
        id="tooltip"
        className={`tooltip ${tooltipShow ? 'tooltip-show ' : ''}`}
        ref={tooltipRef}
      >
        <div className="tooltip-content whitespace-pre-wrap">{content}</div>
        <div id="arrow" data-popper-arrow></div>
      </div>
    </>
  );
};
