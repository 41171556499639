import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import SaveStatus from '../active-input/SaveStatus';
import Label from '../display/Label';
import {
  containsLowercase,
  containsNumber,
  containsSpecialChar,
  containsUppercase,
  has8Char,
} from '../../services/util';
import { fiEye, fiEyeSlash } from '../fluenticons';
import FluentIcon from '../fluenticons/FluentIcon';

export function TextInput(props) {
  const {
    label,
    placeholder,
    required,
    register,
    autoFocus,
    name,
    hint,
    value: origValue,
    disabled,
    maxlength = 50,
    type = 'text',
    onFocus,
    onBlur,
    readOnly = false,
    onEnter,
    error,
    errorSpace = true,
    showError = true,
    style,
    hasBtn = false,
    showPasswordCheck = false,
    onValidation,
    onChange,
    onSave,
    isSaving,
    isSuccess,
    isFailure,
    notWorking,
    info,
    className,
    max = '9999999999',
    min = '0',
    infoColor,
    showEye = true,
    autoComplete = 'on',
    formInput = true,
    rounded = true,
    icon,
    mandatory = false,
  } = props;

  const pattern = type === 'number' ? '[0-9]*' : null;

  const [value, setValue] = useState(origValue);
  const [focused, setFocused] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setValue(origValue);
  }, [origValue]);

  const refReg = register || function () {};

  const VALIDATION = [
    { label: '1 lowercase character', validation: containsLowercase },
    { label: '1 special character', validation: containsSpecialChar },
    { label: '1 uppercase character', validation: containsUppercase },
    { label: '8 characters minimum', validation: has8Char },
    { label: '1 number', validation: containsNumber },
  ];
  const isPassword = type === 'password';

  const [eye, setEye] = useState(fiEyeSlash);

  const toggleEye = () => {
    if (eye === fiEye) {
      setEye(fiEyeSlash);
    } else {
      setEye(fiEye);
    }
  };

  const change = (e) => {
    if (type === 'number') {
      if (!e.target.validity.valid) {
        setValue((v) => v);
        return;
      }

      try {
        parseInt(e.target.value, 10);
      } catch (e) {
        setValue((v) => v);
        return;
      }
    }

    setValue(e.target.value);
    setErrors('');
    if (onChange) {
      onChange(e.target.value);
    }
    if (isPassword && onValidation) {
      const isValid = VALIDATION.map((v) => {
        return v.validation(e.target.value || '');
      }).filter((v) => v);
      onValidation(isValid && isValid.length === 5);
    }
  };

  const focus = (event) => {
    setFocused(true);
    event.target.select();
    if (onFocus) {
      onFocus();
    }
  };

  const blur = () => {
    setFocused(false);
    save(value);
    if (onBlur) {
      onBlur();
    }
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (onEnter) {
        onEnter(value);
      }
      save(value);
    }
  };

  const onKeyPress = (event) => {
    if (type === 'number') {
      const x = event.which || event.keycode;
      if (x >= 48 && x <= 57) {
        setValue(event.target.value);
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  const save = (value) => {
    const errors = props.validate
      ? {
          ...props.validate(name, value, props),
          ...validate(name, value, props),
        }
      : validate(name, value, props);
    if (!errors || (errors && Object.keys(errors).length === 0)) {
      if (onSave && origValue !== value) {
        onSave({ [name]: value });
      }
    } else {
      setErrors(errors);
    }
  };

  const len = value && value.length;

  const is80PerLen = len / maxlength > 0.8 || maxlength - len <= 20;

  return (
    <div className="w-full mb-2 relative" style={style}>
      {label && (
        <Label {...{ label, notWorking, info, infoColor, mandatory }} />
      )}
      {icon && (
        <div
          className="absolute"
          style={{ left: '5px', top: label ? '31px' : '2px' }}
        >
          <FontAwesomeIcon icon={icon} />
        </div>
      )}
      <input
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        disabled={disabled}
        name={name}
        type={
          type === 'password'
            ? eye === fiEyeSlash
              ? 'password'
              : 'text'
            : type
        }
        maxLength={maxlength}
        pattern={pattern}
        min={min}
        max={max}
        ref={refReg({ required: required })}
        value={readOnly ? origValue : onChange ? value || '' : undefined}
        onChange={change}
        onBlur={blur}
        onFocus={focus}
        onKeyDown={onKeyDown}
        readOnly={readOnly}
        className={`border-b-2 ${
          error ? 'border-red-500' : 'border-gray-300 focus:border-brand-base'
        } mt-1 block text-md outline-none text-gray-750 w-full ${className} ${
          icon && 'pl-5'
        }`}
        placeholder={placeholder}
      />
      {type === 'password' && showEye && (
        <div
          className="absolute cursor-pointer text-black"
          style={{ right: '5px', top: '25px' }}
          onClick={toggleEye}
        >
          <FluentIcon icon={eye} />
        </div>
      )}
      {isSaving || isSuccess || isFailure ? (
        <div
          className="absolute right-0 bottom-0 mb-2 mr-2 cursor-pointer"
          style={{
            top: '23px',
            right: hasBtn ? '84px' : isPassword ? '24px' : '0',
          }}
          atitle={isFailure || undefined}
        >
          <SaveStatus
            isSaving={isSaving}
            isSuccess={isSuccess}
            isFailure={isFailure}
          />
        </div>
      ) : (
        <></>
      )}
      {hint && <p className="ml-1 mt-1 text-xxs text-secondary">{hint}</p>}

      {!showPasswordCheck && (
        <div className="mt-1 text-xs flex flex-row">
          <div className="flex-1">
            {(errors && showError && errors[name]) || (error && showError) ? (
              <p className="text-red-500">{error || errors[name]}</p>
            ) : (
              errorSpace && <p>&nbsp;</p>
            )}
          </div>
          <div className="flex-initial text-right text-gray-600">
            {focused && len && is80PerLen ? maxlength - len : ''}
          </div>
        </div>
      )}
      {showPasswordCheck && (
        <div className="grid grid-flow-row grid-cols-2">
          {VALIDATION.map((v, i) => {
            const isValid = v.validation(value || '');
            return (
              <div
                key={i}
                className={`${
                  isValid ? 'text-gray-900' : 'text-gray-600'
                } text-xs`}
              >
                <FontAwesomeIcon
                  icon={faCircle}
                  color={isValid ? 'green' : 'gray'}
                  size="xs"
                  className="mt-4 mr-1 opacity-75"
                />
                <span>{v.label}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

function validate(name, value, props) {
  if ((!value || value.length === 0) && props.required) {
    return { [name]: props.label + ' is required' };
  }

  const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  if (
    value &&
    (!props.type || props.type === 'text') &&
    !props.allowMobile &&
    typeof value === 'string' &&
    value.split('').filter((s) => numbers.indexOf(s) > -1).length > 9
  ) {
    return { [name]: 'Mobile numbers are not allowed.' };
  }

  const letters = /^[\sa-zA-Z]+$/;
  if (value && value.match && !value.match(letters) && props.onlyAlphabet) {
    return { [name]: 'Only alphabets are allowed.' };
  }

  const letterNumber = /^[\s0-9a-zA-Z]+$/;
  if (
    value &&
    value.match &&
    !value.match(letterNumber) &&
    props.onlyAlphanum
  ) {
    return { [name]: 'Only alphabets and numbers are allowed.' };
  }

  const letterNumberSpecial = /^[\s0-9a-zA-Z.,']+$/;
  if (
    value &&
    value.match &&
    !value.match(letterNumberSpecial) &&
    props.onlyAlphanumSpecial
  ) {
    return { [name]: 'Only alphabets and numbers are allowed.' };
  }

  const letterSpecial = /^[\sa-zA-Z.,']+$/;
  if (
    value &&
    value.match &&
    !value.match(letterSpecial) &&
    props.onlyAlphabetSpecial
  ) {
    return { [name]: 'Only alphabets are allowed.' };
  }
  return null;
}
