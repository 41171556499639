import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Tooltip } from '../popovers';

export default function Label({
  label,
  hasError,
  children,
  pl = false,
  notWorking,
  info = 'Backend integration pending',
  infoColor,
  className,
  weight = 'bold',
  style = {},
  mandatory = false,
}) {
  return (
    <label
      className={`${hasError ? 'text-red-500' : ''} font-${weight} relative ${
        className || ''
      }`}
      style={style}
    >
      <span
        className={`${pl && 'ml-1'} whitespace-no-wrap text-md ${
          notWorking === true ? 'text-red-500' : 'text-black'
        }`}
      >
        {label || children}
        {mandatory && <span className="text-red-700">*</span>}
        {notWorking || info !== 'Backend integration pending' ? (
          <Tooltip content={info}>
            <FontAwesomeIcon
              className="ml-2"
              icon={faInfoCircle}
              color={infoColor || 'gray'}
            />
          </Tooltip>
        ) : (
          <></>
        )}
      </span>
    </label>
  );
}
/*
<Tooltip content="" placement="top"><FontAwesomeIcon className="ml-2" title={info || undefined} icon={faInfoCircle} color={infoColor || 'gray'}/></Tooltip>
 */
